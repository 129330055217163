import React from "react";
import { Autocomplete, Avatar, Box, Button, Container, createTheme, CssBaseline, TextField, ThemeProvider, Typography } from "@mui/material";
import { AccountCircleOutlined } from "@mui/icons-material";
import { RegisteredCompany } from "../Models/RegisteredCompany";
import { useLocation } from "react-router-dom";

const defaultTheme = createTheme();
const emailFieldName = "textEditEmail";
const passwordFieldName = "textEditPassword";

export default function Register() {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    const location = useLocation();
    const email:string = location.state.email;
    const companies:Array<RegisteredCompany> = location.state.companies;

    // function getCompanies() : RegisteredCompany[] {
    //     let theCompanies:RegisteredCompany[] = [];
    //     theCompanies.length = companies.length;
    //     let index = 0;
    //     companies.forEach((element) => {
    //         theCompanies[index] = element;
    //         index++;
    //     });
    //     return theCompanies;
    // }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop:8,
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor:'secondary.main'}}>
                        <AccountCircleOutlined />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Regisztráció
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt:1}}>
                        <TextField
                            margin='normal'
                            fullWidth
                            id={emailFieldName}
                            label="Email"
                            name={emailFieldName}
                            type='email'
                            value={email}
                            autoComplete="email"
                            autoFocus
                            disabled
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id={passwordFieldName}
                            label="Jelszó"
                            name={passwordFieldName}
                            type="password"
                            autoFocus
                        />
                        <Autocomplete 
                            disablePortal
                            fullWidth
                            options={companies}
                            getOptionLabel={(company) => company.CompanyName ?? ""}
                            renderInput={(params) => <TextField {...params} label="Alapértelmezett..." />}
                        />
                        <Button type='submit' fullWidth variant='contained' sx={{ mt:3, mb: 2}}>
                            Regisztrál
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    )
}