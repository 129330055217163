import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import EmailCheck from './pages/EmailCheck';

function App() {
  return (
      <Routes>
        <Route path="/" element={<Login /> }/>
        <Route path="/home" element={<Home />}/>
        <Route path="/register" element={<Register /> }/>
        <Route path="/emailcheck" element={<EmailCheck/>}/>
      </Routes>
  );
}

export default App;
