import * as React from 'react';
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { Link, Grid, Box, Typography, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlineIcon from '@mui/icons-material/LockOutlined';
import { LoginCheck } from '../Managers/UserManager'

const defaultTheme = createTheme();

const emailFieldName = "textEditEmail";
const passwordFieldName = "textEditPassword";

// Login
export default function Login() {
    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let email:string = data.get(emailFieldName) as string;
        var password = data.get(passwordFieldName) as string;
        console.log({
            email: email,
            password: password,
        });
        let loginCheck:boolean = LoginCheck(email?.toString(), password);
        // Ha sikeres a login
        if (loginCheck) {

        }
        // Ha nem, akkor valami üzenet féle
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop:8,
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                    }}
                >
                    <Avatar sx={{ m:1, bgcolor:'secondary.main'}}>
                        <LockOutlineIcon />
                    </Avatar>
                    <Typography component="h1" variant='h5'>
                        Belépés
                    </Typography>
                    <Box component="form" onSubmit={handleLogin} noValidate sx={{mt:1}}>
                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            id={emailFieldName}
                            label="Email"
                            name={emailFieldName}
                            type='email'
                            autoComplete='email'
                            autoFocus
                         />
                         <TextField 
                            margin='normal'
                            required
                            fullWidth
                            id={passwordFieldName}
                            label="Jelszó"
                            name={passwordFieldName}
                            type='password'
                            autoComplete='jelszó'
                         />
                         <Button type='submit' fullWidth variant='contained' sx={{ mt:3, mb: 2}}>Belépés</Button>
                         <Grid container>
                            <Grid item xs>
                                <Link href="#" variant='body2'>Ha elfelejtetted a jelszavad.</Link>
                            </Grid>
                            <Grid item>
                                <Link href="/emailcheck" variant='body2'>Itt tudsz regisztrálni.</Link>
                            </Grid>
                         </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    )
}

// const emailFieldName = "textEditEmail"
// const passwordFieldName = "textEditPassword"

// const Login = () => {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [emailError, setEmailError] = useState('');
//     const [passwordError, setPasswordError] = useState('');
//     const navigate = useNavigate();

//     // beállít egy mező értéket
//     const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
//         const { name, value } = event.target
//         if (name === emailFieldName) {
//             setEmail(value)
//         }
//         if (name === passwordFieldName) {
//             setPassword(value)
//         }
//     }

//     // Nyomott login gombot
//     const onButtonClick = () => {

//     }

//     // Itt tér vissza
//     return (
//         <div id="loginPage" className={'controlsContainer'}>
//             <div className={'theTitle'}>
//                 <div>Login</div>
//             </div>
//             <br />
//             <div id="theInputs" className={'theInputs'}>
//                 <TextField
//                     required
//                     margin="normal"
//                     autoFocus
//                     name={emailFieldName}
//                     id={emailFieldName}
//                     label="Email"
//                     value={email}
//                     //onChange={(value) => setEmail(value.target.value)}
//                     onChange={handleInputChanged}
//                     variant="standard"
//                     sx={}
//                 />
//                 <TextField
//                     required
//                     margin="normal"
//                     name={passwordFieldName}
//                     id={passwordFieldName}
//                     label="Jelszó"
//                     type="password"
//                     value={password}
//                     onChange={handleInputChanged}
//                 />
//                 <Button
//                     onClick={onButtonClick}
//                 >Belépés</Button>
//             </div>
//         </div>
//     )
// }

// export default Login;