// import { useState, useEffect } from 'react';
import { OperationResponse } from '../Models/OperationResponse';
import { ApiOperationResponse } from '../Models/ApiOperationResponse';
//import { format } from 'react-string-format';

//const post = 'POST';
const get = 'GET';
const devURL = 'http://localhost:38902';
//const devURL = 'https://localhost:44333';
//const prodURL ='http://81.183.212.64:9001'
const prodURL ='https://centerapi.eurostone.hu'

export class RestApiCalls {
    static async Login(email: string, password: string) : Promise<OperationResponse> {
        //const response = await axios.post('',)
        try {
            // var fetchResponse = await fetch('http://localhost:38902/api/cloudservice/v1/cloudlogin', {
            //     method: post,
            //     headers: {
            //         //'Access-Control-Allow-Origin': '*',
            //         'Content-type': 'application/json',
            //     },
            //     body: JSON.stringify(
            //         {
            //             UserEmailAdress: email,
            //             UserPassword: password
            //         }
            //     ),
            // })
            // .then((callResponse) => callResponse.json())
            // .then((data) => {
               
            // });
            // // .catch((e) => {
    
            // // });
        }
        catch(error) {
            console.log(error);
        }

        var result = new OperationResponse();
        return result;
    }

    static async RegisterEmailCheck(email: string) : Promise<OperationResponse> {
        let result:OperationResponse|null;
        try {
            let url:string = prodURL + '/api/cloudservice/v1/emailcheck';
            console.log(url);
            console.log(email);
            var fetchResponse = await fetch(url, {
                method: get,
                mode: 'cors',
                headers: {
                    // "Access-Control-Allow-Origin": "*",
                    // "Access-Control-Allow-Methods": "GET",
                    // "Access-Control-Allow-Headers": "content-type",
                    'emailAddress': email,
                },
            });
            let data:ApiOperationResponse = await fetchResponse.json();
            result = new OperationResponse();
            result.MapFrom(data);
        }
        catch(error) {
            result = new OperationResponse();
            result.Success = false;
            result.message = (error as Error).message;
        }

        return result!;
    }

}