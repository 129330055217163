import { sha512 } from "js-sha512";
import { RestApiCalls } from '../Services/RemoteCallService';
import { OperationResponse } from "../Models/OperationResponse";
import { RegisteredCompany } from "../Models/RegisteredCompany";
import { CompanyResponse } from "../Models/ResponseContents/CompanyResponse";

/**
 * Ellenőrzi, hogy a megadott adatok alapján a user be tud-e jelentkezni
 * @param email Email
 * @param password Jelszó
 * @returns 
 */
export function LoginCheck(email: string, password: string): boolean {
    var hashPassword = sha512(password);
    RestApiCalls.Login(email, hashPassword).then(result => {
    });
    return true;
}

/**
 * A megadott adatokkal regisztrál
 * @param email Email cím
 * @param password Jelszó
 * @param defaultCompany Alapértelmezett vállalat, ahova bejelentkezik
 * @returns 
 */
export function RegisterUser(email: string, password: string, defaultCompany: string): boolean {
    return false;
}

/**
 * A megadott email cím szerint visszaadja azokat a cégeket, ahova a user bejelentkezhet
 * @param email A user email címe
 */
export async function FetchCompaniesByUserEmail(email: string): Promise<Array<RegisteredCompany>> {
    let companies:Array<RegisteredCompany> = [];
    let operationResponse:OperationResponse|null;
    await RestApiCalls.RegisterEmailCheck(email).then(function (result) {
        operationResponse = result;
    });
    if (operationResponse!.Success && operationResponse!.content != null) {
        let content:CompanyResponse[] = operationResponse!.content as CompanyResponse[];
        companies = new Array<RegisteredCompany>(content.length)
        for(let index = 0; index <= content.length -1; index++) {
            let company:RegisteredCompany = new RegisteredCompany();
            company.CompanyName = content[index].companyCode;
            company.CompanyId = content[index].companyId;
            companies[index] = company;
        }
    }
    return companies;
}
