export enum StatusCodesEnum {
    Status200Ok = 200,
    Status201Created = 201,
    Status202Accepted=202,
    Status204NoContent = 204,
    Status208AlreadyReported = 208,
    Status401Unauthorized=401,
    Status409Conflict = 409,
    Status400BadRequest=400,
    Status500InternalServerError=500
}
