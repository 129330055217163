import { StatusCodesEnum } from "../Common/CommonEnums";
import { ApiOperationResponse } from "./ApiOperationResponse";

/**
 * Műveletek response osztálya
 */
export class OperationResponse {
    constructor() {
        this.MapFrom = this.MapFrom.bind(this);
    }

    /**
     * A válasz kódja
     */
    responseCode?:string|null;
    /**
     * A válasz üzenete, ha van
     */
    message?:string|null;
    /**
     * Részletes üzenet, leírás, egyéb, ha van
     */
    details?:string|null;
    /**
     * A válaszban visszakapott bármilyen objektum
     */
    content?:any|null;
    /**
     * Sikeres-e a művelet
     * Alapértelmezett: nem
     */
    Success:boolean = false;

    MapFrom(source:ApiOperationResponse) : void {
        this.content = source.content;
        this.details = source.details;
        this.message = source.message;
        this.responseCode = source.responseCode;
        this.Success = source.statusCode === StatusCodesEnum.Status200Ok ? true : false;
        return;
    }
}