import React from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Container, createTheme, CssBaseline, TextField, ThemeProvider, Typography } from "@mui/material";
import { AccountCircleOutlined } from "@mui/icons-material";
import { RegisteredCompany } from '../Models/RegisteredCompany';
import { FetchCompaniesByUserEmail } from '../Managers/UserManager';

const defaultTheme = createTheme();
const emailFieldName = "textEditEmail";

export default function EmailCheck() {
    // state = {
    //     validEmail: false,
    //     data: Array<RegisteredCompany>
    // }

    // handleEmailCheck = async (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     let email = data.get(emailFieldName) as string;
    //     let companies:Array<RegisteredCompany> = await FetchCompaniesByUserEmail(email);
    //     if (companies != null && companies.length > 0) {
    //         this.setState({
    //             validEmail: true,
    //             data: companies,
    //         })
    //         let navigate = useNavigate();
    //         navigate('/register');
    //     }
    // }

    // render () {
    //     return (
    //         <ThemeProvider theme={defaultTheme}>
    //             <Container component="main" maxWidth="xs">
    //                 <CssBaseline />
    //                 <Box
    //                     sx={{
    //                         marginTop:8,
    //                         display:'flex',
    //                         flexDirection:'column',
    //                         alignItems:'center',
    //                     }}
    //                 >
    //                     <Avatar sx={{ m:1, bgcolor:'secondary.main'}}>
    //                         <AccountCircleOutlined/>
    //                     </Avatar>
    //                     <Typography component="h1" variant='h5'>
    //                         Email ellenőrzés
    //                     </Typography>
    //                     <Box component="form" onSubmit={this.handleEmailCheck} noValidate sx={{mt:1}}>
    //                         <TextField
    //                             margin='normal'
    //                             required
    //                             fullWidth
    //                             id={emailFieldName}
    //                             label='Email'
    //                             name={emailFieldName}
    //                             type='email'
    //                             autoComplete='email'
    //                             autoFocus
    //                         />
    //                         <Button type='submit' fullWidth variant='contained' sx={{mt: 3, mb: 2}}>
    //                             Ellenőrzés
    //                         </Button>
    //                     </Box>
    //                 </Box>
    //             </Container>
    //         </ThemeProvider>
    //     )
    // }

    async function handleEmailCheck(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let email = data.get(emailFieldName) as string;
        let companies:Array<RegisteredCompany> = await FetchCompaniesByUserEmail(email);
        if (companies != null && companies.length > 0) {
            routeChange(email, companies);
            return;
        }
        alert('A megadott email cím nem szerepel egyik adatbázisban sem!');
    }

    let navigate = useNavigate();
    const routeChange = (emailAddress:string, companies:Array<RegisteredCompany>) => {
        let navOptions:NavigateOptions = {
            state:{
                email: emailAddress,
                companies: companies,
            }
        }
        navigate('/register', navOptions);
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop:8,
                        display:'flex',
                        flexDirection:'column',
                        alignItems:'center',
                    }}
                >
                    <Avatar sx={{ m:1, bgcolor:'secondary.main'}}>
                        <AccountCircleOutlined/>
                    </Avatar>
                    <Typography component="h1" variant='h5'>
                        Email ellenőrzés
                    </Typography>
                    <Box component="form" id='containerEmail' onSubmit={handleEmailCheck} noValidate sx={{mt:1}}>
                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            id={emailFieldName}
                            label='Email'
                            name={emailFieldName}
                            type='email'
                            autoComplete='email'
                            autoFocus
                        />
                        <Button type='submit' fullWidth variant='contained' sx={{mt: 3, mb: 2}}>
                            Ellenőrzés
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    )
}